import SwaggerUI from 'swagger-ui';
import { AfterContentInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Constants } from '../../../shared/constants';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../../components/notification/notification.service';

@Component({
  selector: 'app-swagger-page',
  templateUrl: './swagger-page.component.html',
  styleUrls: ['./swagger-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SwaggerPageComponent implements OnInit, AfterContentInit {
  public specs = new Map<string, object>();

  public diagnosisServiceName = 'Diagnosis service';
  public configServiceName = 'Config service';

  public selectedService: string | undefined;

  constructor(private http: HttpClient, private notificationService: NotificationService) {}

  ngAfterContentInit(): void {}

  ngOnInit(): void {
    this.http
      .get<object>(Constants.diagnosisServiceBaseURL + '/swagger/v3/api-docs', {
        observe: 'response'
      })
      .subscribe(
        (res) => {
          this.addSpec(this.diagnosisServiceName, res.body);
        },
        (error) => {
          this.notificationService.error(
            'Diagnosis service not found',
            'diagnosis service did not responded with api doc, message: ' + error.message
          );
        }
      );

    this.http
      .get<object>(Constants.configServiceBaseURL + '/swagger/v3/api-docs', {
        observe: 'response'
      })
      .subscribe(
        (res) => {
          this.addSpec(this.configServiceName, res.body);
        },
        (error) => {
          this.notificationService.error(
            'Config service not found',
            'config service did not responded with api doc, message: ' + error.message
          );
        }
      );
  }

  private addSpec(serviceName: string, apiSpec: object) {
    this.specs.set(serviceName, apiSpec);

    if (serviceName === this.diagnosisServiceName) {
      this.updateSwagger(serviceName);
    }
  }

  public updateSwagger(serviceName: string) {
    this.selectedService = serviceName;
    const ui = SwaggerUI({
      spec: this.specs.get(serviceName),
      domNode: document.getElementById('customer-api-documentation')
    });
  }

  public specKeys() {
    return Array.from(this.specs.keys()).filter((k) => this.specs.get(k) !== undefined);
  }
}

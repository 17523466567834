import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from 'ngx-typesafe-forms';
import { BaseDiagnosisComponent } from 'src/app/components/diagnosis/base-diagnosis/base-diagnosis.component';
import { NotificationService } from '../../components/notification/notification.service';
import { Constants, Role } from '../../shared/constants';
import { TraceRequestForm } from '../../shared/models/diagnosis.model';
import { TraceDiagnosisService } from '../../shared/services/diagnosis.service/diagnosis.service';
import { RolesService } from '../../shared/services/roles-service.service';
import { UserService } from '../../shared/services/user-service.service';
import { ServicesService } from '../../shared/services/services.service';
import { DiagnosisLanguageService } from '../../shared/services/diagnosis-language.service';
import { HttpClient } from '@angular/common/http';

import { traceIdValidator } from 'src/app/shared/validators/traceid-validator.directive';
@Component({
  selector: 'app-traceid-diagnosis',
  templateUrl: './traceid-diagnosis.component.html',
  styleUrls: ['./traceid-diagnosis.component.scss']
})
export class TraceIdDiagnosisComponent extends BaseDiagnosisComponent<TraceRequestForm> {
  public readonly ADMIN_COMPETENCE = [Role.COMPETENCE_CENTER, Role.ADMIN];
  public readonly TRACEID_KEY = 'traceId';
  public Role = Role;
  public readonly BRAND_PRODUCTION = Constants.BRAND_PRODUCTION;

  constructor(
    diagnosisLanguageService: DiagnosisLanguageService,
    servicesService: ServicesService,
    diagnosisService: TraceDiagnosisService,
    notificationService: NotificationService,
    userService: UserService,
    rolesService: RolesService,
    http: HttpClient
  ) {
    super(
      diagnosisLanguageService,
      servicesService,
      diagnosisService,
      notificationService,
      userService,
      rolesService,
      http
    );

    // @ts-ignore
    super.diagnoses = null;
  }

  protected extendForm(form: FormGroup<TraceRequestForm>) {
    form.addControl(
      this.TRACEID_KEY,
      new FormControl<string>(this.diagnosisService.getFormValue().traceId, [Validators.required])
    );
    form.addValidators([traceIdValidator()]);
  }
}

import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { CoreService } from 'src/app/shared/interfaces/core-service.interface';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnChanges, AfterViewInit {
  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.currentlySelectedService = this.currentlySelectedService;
  }

  @Input() customerServices: CoreService[] = [];
  @Input() coreServices: CoreService[] = [];
  @Input() customizedServices: CoreService[] = [];
  @Input() favouriteServices: CoreService[] = [];

  @Input() handleServiceChange = (service: CoreService): void => {};
  @Input() selectedService: CoreService;

  public inputValue: string = '';

  public displayedCoreServices: CoreService[] = this.coreServices;
  public displayedCustomerServices: CoreService[] = this.customerServices;
  public displayedCustomizedServices: CoreService[] = this.customizedServices;
  public displayedFavouriteServices: CoreService[] = [];
  public isDropdownOpen: boolean = false;
  public currentlySelectedService: CoreService;

  private backdrop = this.renderer.createElement('div');

  private handleFocusOut(): void {
    this.renderer.removeChild(document.body, this.backdrop);
    this.isDropdownOpen = false;
  }

  public handleFocus(): void {
    const searchInput = document.getElementById('search') as HTMLInputElement;
    searchInput.select();
    this.renderer.appendChild(document.body, this.backdrop);
    this.renderer.addClass(this.backdrop, 'search-dropdown-overlay');
    this.isDropdownOpen = true;
  }

  public handleSearch(event: { target: HTMLInputElement }) {
    const searchValue: string = event.target.value;
    this.inputValue = searchValue;
    const filteredCoreServices: CoreService[] = this.coreServices.filter((service) =>
      service.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    const filteredCustomerServices: CoreService[] = this.customerServices.filter((service) =>
      service.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    const filteredFavouriteServices: CoreService[] = this.favouriteServices.filter((service) =>
      service.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const filteredCustomizedServices: CoreService[] = this.customizedServices.filter((service) =>
      service.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    this.displayedCoreServices = filteredCoreServices;
    this.displayedCustomerServices = filteredCustomerServices;
    this.displayedCustomizedServices = filteredCustomizedServices;
    this.displayedFavouriteServices = filteredFavouriteServices;
  }

  public handleSelect(event: { target: HTMLLIElement }): void {
    const { id } = event.target;
    const newSelectedService = [
      ...this.coreServices,
      ...this.customerServices,
      ...this.customizedServices,
      ...this.favouriteServices
    ].find((service) => service.modId === id);
    this.inputValue = newSelectedService.name;
    this.currentlySelectedService = newSelectedService;

    this.handleServiceChange(this.currentlySelectedService);
    this.handleFocusOut();
    this.isDropdownOpen = false;
  }

  ngAfterViewInit() {
    this.renderer.listen(this.backdrop, 'click', () => {
      this.handleFocusOut();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentlySelectedService = this.selectedService;

    this.displayedCoreServices = this.coreServices;

    this.displayedCustomerServices = this.customerServices;

    this.displayedCustomizedServices = this.customizedServices;

    this.displayedFavouriteServices = this.favouriteServices;

    this.inputValue = this.currentlySelectedService ? this.currentlySelectedService.name : '';
  }
}

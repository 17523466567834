/** At least vin or user id has to be filled */
import { ValidatorFn } from 'ngx-typesafe-forms';

export const userIdValidator = <T extends { user: string }>(
  vinRequiredByServiceSupplier: () => boolean,
  userIdRequiredByServiceSupplier: () => boolean
): ValidatorFn<T> => (control) => {
  const { user } = control.value;

  const requiredByService = userIdRequiredByServiceSupplier() && !vinRequiredByServiceSupplier();

  return requiredByService ? (user?.length !== 0 ? null : { userIdRequired: true }) : null;
};

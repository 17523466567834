import { AuthConfig } from 'angular-oauth2-oidc';
import { env } from './environment';

export const authConfig: AuthConfig = {
  issuer: env.issuer,
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  clientId: env.clientId,
  scope: 'openid profile email offline_access',
  showDebugInformation: true,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  useSilentRefresh: true
};

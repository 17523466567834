<div class="row row--container mar-top-large">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <div class="row">
          <h4>{{ 'DATA_PROTECTION_REGULATION.HEADER' | translate }}</h4>

          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row row--container">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <h6>A.{{ 'DATA_PROTECTION_REGULATION.SUBHEADER_1' | translate }}</h6>

        <p>
          {{ 'DATA_PROTECTION_REGULATION.INTRODUCTION_PART_1' | translate }}
          <a href="https://www.volkswagenag.com/de/meta/provider-identification.html"
            >https://www.volkswagenag.com/de/meta/provider-identification.html</a
          >{{ 'DATA_PROTECTION_REGULATION.INTRODUCTION_PART_2' | translate }}
        </p>

        <br />

        <h6>B.{{ 'DATA_PROTECTION_REGULATION.SUBHEADER_2' | translate }}</h6>

        <br />

        <h5>I.{{ 'DATA_PROTECTION_REGULATION.LOG_FILE_PROCESS' | translate }}</h5>

        <br />

        <p>
          {{ 'DATA_PROTECTION_REGULATION.NEEDED_DOCUMENTS_TEXT' | translate }}
        </p>

        <br />
        <ol>
          <li>
            <p>{{ 'DATA_PROTECTION_REGULATION.LIST_ELEMENT_1' | translate }}</p>
          </li>
          <li>
            <p>{{ 'DATA_PROTECTION_REGULATION.LIST_ELEMENT_2' | translate }}</p>
          </li>
          <li>
            <p>{{ 'DATA_PROTECTION_REGULATION.LIST_ELEMENT_3' | translate }}</p>
          </li>
          <li>
            <p>{{ 'DATA_PROTECTION_REGULATION.LIST_ELEMENT_4' | translate }}</p>
          </li>
          <li>
            <p>{{ 'DATA_PROTECTION_REGULATION.LIST_ELEMENT_5' | translate }}</p>
          </li>
          <li>
            <p>{{ 'DATA_PROTECTION_REGULATION.LIST_ELEMENT_6' | translate }}</p>
          </li>
        </ol>
        <br />

        <p>
          {{ 'DATA_PROTECTION_REGULATION.ELASTIC_STACK_EXPLAINED' | translate }}
        </p>

        <p>
          {{ 'DATA_PROTECTION_REGULATION.WHAT_HAPPENS_TO_DATA' | translate }}
        </p>

        <br />

        <h6>C.{{ 'DATA_PROTECTION_REGULATION.SUBHEADER_3' | translate }}</h6>

        <br />
        <p>
          {{ 'DATA_PROTECTION_REGULATION.WHERE_TO_FIND_RIGHTS' | translate }}
        </p>
        <br />
        <p>
          <b>{{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_INFORMATION' | translate }}</b
          >{{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_INFORMATION_TEXT' | translate }}
        </p>
        <br />

        <p>
          <b>{{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_RECTIFICATION' | translate }}</b
          >{{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_RECTIFICATION_TEXT' | translate }}
        </p>

        <br />
        <p>
          <b>{{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_DELETION' | translate }}</b>
          {{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_DELETION_TEXT' | translate }}
        </p>
        <br />

        <p>
          <b>{{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_PROCESSING_RESTRICTION' | translate }}</b>
          {{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_PROCESSING_RESTRICTION_TEXT' | translate }}
        </p>
        <br />

        <p class="one">
          <b>{{ 'DATA_PROTECTION_REGULATION.RIGHT_TO_OBJECT' | translate }}</b>
          {{ 'DATA_PROTECTION_REGULATION.RIGHT_TO_OBJECT_TEXT' | translate }}
        </p>
        <br />

        <p>
          <b>{{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_DATATRANSFER' | translate }}</b>
          {{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_DATATRANSFER_TEXT' | translate }}
        </p>
        <br />

        <p>
          <b>{{ 'DATA_PROTECTION_REGULATION.RIGHT_TO_WITHDRAWAL' | translate }}</b>
          {{ 'DATA_PROTECTION_REGULATION.RIGHT_TO_WITHDRAWAL_TEXT' | translate }}
        </p>
        <br />

        <p>
          <b>{{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_COMPLAINT' | translate }}</b>
          {{ 'DATA_PROTECTION_REGULATION.RIGHT_OF_COMPLAINT_TEXT' | translate }}
        </p>
        <br />

        <h6>D.{{ 'DATA_PROTECTION_REGULATION.SUBHEADER_4' | translate }}</h6>

        <br />

        <p>
          <b>{{ 'DATA_PROTECTION_REGULATION.CONTACT_PERSON_RIGHTS' | translate }}</b>
        </p>
        <br />

        <p>
          {{ 'DATA_PROTECTION_REGULATION.CONTACT_PERSON_RIGHTS_TEXT' | translate }}
          <a href="https://datenschutz.volkswagen.de">https://datenschutz.volkswagen.de</a>
        </p>

        <br />
        <p>
          <b>{{ 'DATA_PROTECTION_REGULATION.DATAPROTECTION_OFFICER' | translate }}</b>
        </p>

        <p>
          {{ 'DATA_PROTECTION_REGULATION.DATAPROTECTION_OFFICER_TEXT' | translate }}
        </p>

        <p>
          {{ 'DATA_PROTECTION_REGULATION.DATAPROTECTION_OFFICER_TEXT_2' | translate }}
        </p>

        <p>{{ 'DATA_PROTECTION_REGULATION.ADDRESS' | translate }}</p>

        <p>
          <a href="mailto:datenschutz@volkswagen.de">datenschutz&#64;volkswagen.de</a>
        </p>

        <br />
      </div>
    </div>
  </div>
</div>

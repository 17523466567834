/** At least vin or user id has to be filled */
import { ValidatorFn } from 'ngx-typesafe-forms';

export const vinValidator = <T extends { vin: string }>(
  vinRequiredByServiceSupplier: () => boolean,
  userIdRequiredByServiceSupplier: () => boolean
): ValidatorFn<T> => (control) => {
  const { vin } = control.value;
  const requiredByService = vinRequiredByServiceSupplier() && !userIdRequiredByServiceSupplier();

  return requiredByService ? (vin?.length !== 0 ? null : { vinRequired: true }) : null;
};

import { Component, Input, OnChanges, Renderer2 } from '@angular/core';
import { Diagnosis } from '../../../shared/models/diagnosis.model';

@Component({
  selector: 'app-additional-parameters-modal',
  templateUrl: './additonal-parameters-modal.component.html',
  styleUrls: ['./additonal-parameters-modal.component.scss']
})
export class AdditionalParametersModalComponent implements OnChanges {
  @Input() diagnosis: Diagnosis;
  opened: boolean;
  iterableAdditionalParameters: any[];
  diagnosisAdditionalParameters: any[];

  // Used only for WAP service and is displayed in table format
  // Should not be displayed!
  parametersToIgnore = [
    'Webapp List',
    'Web App Installation State',
    'Web App Versions',
    'Names of installed Web Apps (same app order as for the other parameters!)'
  ];

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes) {
    let nonIterableParams = [];

    if (
      changes.diagnosis.currentValue.isTableView &&
      changes.diagnosis.currentValue.additionalParameters.length > 0
    ) {
      const iterable = changes.diagnosis.currentValue.additionalParameters.find(
        (additionalParam) => additionalParam.name === 'Webapp List'
      );
      nonIterableParams = changes.diagnosis.currentValue.additionalParameters.filter(
        (additionalParameter) => !this.parametersToIgnore.includes(additionalParameter.name)
      );

      if (iterable && iterable.value.length > 0) {
        let iterrableParams = [];
        JSON.parse(iterable.value).map((param) => {
          if (param.installedVersions.length > 0) {
            for (let version of param.installedVersions) {
              iterrableParams.push({
                name: param.productId,
                version: version.version,
                status: version.installationState
              });
            }
          }
        });
        this.iterableAdditionalParameters = iterrableParams;
      }
    }

    this.diagnosisAdditionalParameters =
      nonIterableParams.length > 0 ? nonIterableParams : this.diagnosis.additionalParameters;
  }

  open() {
    this.renderer.addClass(document.body, 'modal-open');
    this.opened = true;
  }

  close() {
    this.renderer.removeClass(document.body, 'modal-open');
    this.opened = false;
  }

  paramsAvailable() {
    if (this.diagnosis) {
      const emptyParams = this.diagnosis.additionalParameters.filter(
        (param) => !!param.value || param.value.length > 0
      );

      if (emptyParams.length === 0) {
        return false;
      }

      return this.diagnosis.additionalParameters.length > 0;
    }

    return false;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactpersons',
  templateUrl: './contactpersons.component.html',
  styleUrls: ['./contactpersons.component.scss']
})
export class ContactpersonsComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    console.log('Contact person page opened');
  }
}

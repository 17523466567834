import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { CoreService } from '../interfaces/core-service.interface';
@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  constructor(private http: HttpClient) {}

  getCoreServices() {
    return this.http.get<CoreService[]>(`${Constants.CORE_SERVICES_URL}`);
  }
  getCustomizedServices() {
    return this.http.get<CoreService[]>(`${Constants.CUSTOMIZED_SERVICES_URL}`);
  }
  getFavouriteServices() {
    return this.http.get<string[]>(`${Constants.FAVOURITE_SERVICES_URL}`);
  }
}

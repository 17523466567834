<mat-sidenav-container>
  <mat-sidenav #sidenav [(opened)]="opened" mode="over" position="end">
    <app-service-descriptions-list
      [customerServicesToDisplay]="this.customerServicesToDisplay"
      [inputSideNav]="sidenav"
      [servicesToDisplay]="this.servicesToDisplay"
      [customizedServicesToDisplay]="this.customizedServicesToDisplay"
    ></app-service-descriptions-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div
      *ngIf="rolesService.checkIfUserHasAnyOfPermissions(ADMIN_COMPETENCE); else elseBlock"
      class="traceid-diagnosis-component"
    >
      <div class="row row--container mar-top-large">
        <div class="col sm-12">
          <div class="card">
            <div class="card__content">
              <h1 class="h5 center">{{ 'DIAGNOSIS.TITLE' | translate }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="row row--container mar-top-large">
        <div class="col sm-12">
          <div class="card">
            <div class="card__content">
              <form (ngSubmit)="onSubmit()" [formGroup]="diagnosisForm">
                <div class="row horizontal-margins-small pad-horizontal-small">
                  <div *ngFor="let brand of rolesService.getBrandsOfUser(); let i = index">
                    <div
                      (click)="changeBrandValue(brand)"
                      [ngClass]="diagnosisForm.get(BRAND_KEY).value === brand ? 'btn--primary' : ''"
                      class="btn btn--fab"
                      id="{{ brand }}-button"
                    >
                      <img
                        alt="{{ brand }}"
                        class="taskbar__picture"
                        src="/assets/images/brand-logos/{{ brand }}.png"
                      />
                    </div>
                  </div>

                  <div class="roles-wrapper horizontal-margins-small">
                    <div
                      *ngFor="let role of brandRoles; let i = index"
                      [ngClass]="diagnosisForm.get(ROLE_KEY).value === role ? 'btn--primary' : ''"
                      class="btn btn--fab"
                      id="{{ role }}-button"
                    >
                      <img
                        (click)="changeFormRoleValue(role)"
                        alt="{{ getRoleToDisplay(role) }}"
                        class="taskbar__picture"
                        src="assets/images/role-avatars/ICON_{{ getRoleToDisplay(role) }}.png"
                        title="{{ role }}"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col sm-6">
                    <div class="wrapper wrapper--input">
                      <label for="TraceIdInput">{{ 'DIAGNOSIS.FORM.TRACE_ID' | translate }}</label>
                      <input
                        formControlName="{{ TRACEID_KEY }}"
                        id="TraceIdInput"
                        name="TraceIdInput"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col sm-3" title="{{ 'DIAGNOSIS.FORM.SERVICE_TOOLTIP_DESCRIPTION' | translate }}">
                    <label for="ModIDInput"
                      >{{ 'DIAGNOSIS.FORM.NAME' | translate }}
                      <div class="wrapper">
                        <app-search-dropdown
                          [coreServices]="servicesToDisplay"
                          [customerServices]="customerServicesToDisplay"
                          [handleServiceChange]="handleServiceChange"
                          [favouriteServices]="favouriteServicesToDisplay"
                          [selectedService]="selectedService"
                        >
                        </app-search-dropdown>
                      </div>
                    </label>
                  </div>

                  <div class="col sm-1" title="{{ 'DIAGNOSIS.FORM.INFO_TOOLTIP_DESCRIPTION' | translate }}">
                    <br />
                    <button
                      (click)="sidenav.toggle()"
                      class="btn btn--fab-text btn--tertiary"
                      id="infoButton"
                      type="button"
                    >
                      {{ 'DIAGNOSIS.FORM.INFO' | translate }}
                      <i class="icon i-information"></i>
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="wrapper wrapper--input col sm-3">
                    <label for="StartTimeInput">{{ 'DIAGNOSIS.FORM.STARTTIME' | translate }}</label>
                    <input
                      [max]="latestDate()"
                      [min]="earliestDate()"
                      [owlDateTimeTrigger]="dtFrom"
                      [owlDateTime]="dtFrom"
                      formControlName="{{ FROM_KEY }}"
                      id="StartTimeInput"
                      name="StartTimeInput"
                      type="text"
                    />
                    <owl-date-time
                      #dtFrom
                      (afterPickerClosed)="onChangeFrom(dtFrom)"
                    ></owl-date-time>
                  </div>
                  <div class="wrapper wrapper--input col sm-3">
                    <label for="EndTimeInput">{{ 'DIAGNOSIS.FORM.ENDTIME' | translate }}</label>
                    <input
                      [max]="latestDate()"
                      [min]="earliestDate()"
                      [owlDateTimeTrigger]="dtTo"
                      [owlDateTime]="dtTo"
                      formControlName="{{ TO_KEY }}"
                      id="EndTimeInput"
                      name="EndTimeInput"
                      type="text"
                    />
                    <owl-date-time #dtTo (afterPickerClosed)="onChangeTo(dtTo)"></owl-date-time>
                  </div>

                  <div class="col sm-3">
                    <label for="PageSizeInput"
                      >{{ 'DIAGNOSIS.FORM.PAGE_SIZE' | translate }}
                      <div class="wrapper wrapper--select">
                        <select
                          formControlName="{{ PAGE_SIZE_KEY }}"
                          id="PageSizeInput"
                          name="PageSizeInput"
                          required
                        >
                          <option
                            *ngFor="let pageSizeOption of pageSizeOptions; let i = index"
                            [ngValue]="pageSizeOption"
                            [selected]="pageSizeOption === diagnosisForm.get('pageSize').value"
                          >
                            {{ pageSizeOption }}
                          </option>
                        </select>
                      </div>
                    </label>
                  </div>

                  <div class="wrapper wrapper--input col sm-2" title="{{ 'DIAGNOSIS.FORM.ONLY_ERRORS_TOOLTIP_DESCRIPTION' | translate }}">
                    <br />
                    <input
                      formControlName="{{ FILTER_TOGGLE_KEY }}"
                      id="ResultType"
                      name="ResultType"
                      type="checkbox"
                    />
                    <label class="is-switch" for="ResultType">
                      {{ 'DIAGNOSIS.FORM.RESULT_FILTER' | translate }}
                    </label>
                  </div>

                  <div class="col">
                    <div class="wrapper center">
                      <br />
                      <button
                        [attr.disabled]="
                          (servicesToDisplay.length === 0 && customerServicesToDisplay.length === 0
                            ? true
                            : null) || diagnosisForm.hasError('traceIdRequired')
                            ? true
                            : null
                        "
                        class="btn btn--primary"
                        id="diagnosisFormSubmit"
                        type="submit"
                      >
                        {{ 'DIAGNOSIS.FORM.SUBMIT' | translate }}
                      </button>
                    </div>
                  </div>
                </div>

                <app-form-error
                  [visible]="diagnosisForm.controls.traceId.hasError('required') && submitted"
                >
                  {{ 'DIAGNOSIS.FORM.FIELD_REQUIRED' | translate: { field: 'Trace ID' } }}
                </app-form-error>

                <app-form-error
                  [visible]="diagnosisForm.controls.to.hasError('forbidden') && submitted"
                >
                  {{ 'DIAGNOSIS.FORM.DATE_TO_NOT_VALID' | translate }}
                </app-form-error>

                <app-form-error
                  [visible]="diagnosisForm.controls.from.hasError('forbidden') && submitted"
                >
                  {{ 'DIAGNOSIS.FORM.DATE_FROM_NOT_VALID' | translate }}
                </app-form-error>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="warningMessage" class="row row--container">
        <div class="col sm-12">
          <div *ngFor="let entry of warningMessage | keyvalue" class="card not-found">
            <div>
              <b *ngIf="' ' !== entry.key">{{ entry.key }}: </b>{{ entry.value | translate }}
            </div>
          </div>
        </div>
      </div>
      <app-num-of-hits-chart
        *ngIf="
          this.diagnoses && this.diagnoses.length && this.diagnosedService?.generation !== 'MOD 3'
        "
        [diagnosisForm]="this.diagnosisForm"
        [diagnosisService]="this.diagnosisService"
        [selectedService]="this.selectedService"
        [isChartOpen]="this.isChartOpen"
        [chartOpenHandler]="this.toggleIsChartOpen"
        [numOfResults]="this.totalNumOfResults"
        [numOfSkippedResults]="this.totalNumOfSkippedResults"
      ></app-num-of-hits-chart>

      <app-diagnosis-table
        *ngIf="diagnoses"
        [diagnoses]="diagnoses"
        [diagnosisForm]="diagnosisForm.value"
        [diagnosisRequestParams]="diagnosisService.getCachedRequestParams()"
        [handleNextPage]="handleNextPage"
        [handlePreviousPage]="handlePreviousPage"
        [currentScrollId]="currentScrollId"
        [previousDiagnoses]="previousDiagnoses"
        [offset]="offset"
        [isLastPage]="isLastPage"
        [currentPageNumber]="currentPageNumber"
      ></app-diagnosis-table>

      <div *ngIf="backendCalled" class="row row--container">
        <div class="col sm-12">
          <div class="card">
            <div class="card__content col sm-4 sm-offset-5">
              <div class="preloader preloader--golf"></div>
              <h6>{{ 'DIAGNOSIS.FORM.DIAGNOSIS_IN_PROGRESS' | translate }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #elseBlock>
      <app-unathorized-access></app-unathorized-access>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>

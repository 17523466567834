import { FormGroup, ValidatorFn } from 'ngx-typesafe-forms';
import { DiagnosisForm } from '../models/diagnosis.model';

export const datePickerErrorTransformValidator = <T extends DiagnosisForm>(
  form: FormGroup<T>
): ValidatorFn<T> => (control) => {
  let error;
  if (form.controls.from.errors != null) {
    error = { forbidden: { value: form.controls.from.value } };
  } else {
    error = null;
  }
  return error;
};

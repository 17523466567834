<div class="row row--container">
  <div class="col sm-12">
    <div class="card">
      <div class="card__content diagnosis-table">
        <!---start-->
        <div
          *ngFor="let diagnosis of diagnosesToShow; let i = index"
          class="diagnosis-table-content"
          [ngClass]="{
            error: diagnosis.failureType === 'error',
            success: diagnosis.failureType === 'success',
            topborder: i !== 0
          }"
        >
          <div class="row diagnosis-header">
            <b class="col sm-9 pad-vertical-small pad-horizontal-small">
              {{ 'DIAGNOSIS.TABLE.SOURCE' | translate }}
              {{ diagnosis.modName + ' [' + diagnosis.modId + ']' }}
            </b>
            <div class="col sm-3 pad-vertical-small pad-horizontal-small" style="text-align: right">
              <p *ngIf="diagnosis.events !== null && diagnosis.events !== undefined">
                {{ diagnosis.events[0]?.eventTime | date: dateTimeFormat }}
              </p>
              <p *ngIf="diagnosis.events === null || diagnosis.events === undefined">
                {{ diagnosis.eventTime | date: dateTimeFormat }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col sm-4 pad-vertical-medium pad-horizontal-small">
              <div class="text-display-box" #failuresDescription>
                <div markdown class="markdown-body">
                  {{ diagnosis.failureDescription }}
                </div>
              </div>
            </div>
            <div class="col sm-7 pad-vertical-medium pad-horizontal-small">
              <div class="text-display-box" #solution>
                <div markdown class="markdown-body">
                  {{ diagnosis.solution }}
                </div>
              </div>
            </div>

            <div
              class="col sm-1 pad-vertical-medium pad-horizontal-small flex flex--direction-column flex--align-items-center"
            >
              <button
                class="btn btn--fab btn--tertiary mar-small"
                type="submit"
                title="{{ 'DIAGNOSIS.TABLE.LIKE' | translate }}"
                (click)="onFeedbackButtonClick('POSITIVE', diagnosis)"
              >
                <i class="icon i-like like"></i>
              </button>
              <button
                class="btn btn--fab btn--tertiary mar-small"
                type="submit"
                title="{{ 'DIAGNOSIS.TABLE.DISLIKE' | translate }}"
                (click)="onFeedbackButtonClick('NEGATIVE', diagnosis)"
              >
                <i class="icon i-like dislike"></i>
              </button>
            </div>
          </div>
          <div class="row flex--justify-center">
            <app-more-less-wrapper [resizableReferences]="[failuresDescription, solution]">
            </app-more-less-wrapper>
            <app-additional-parameters-modal *ngIf="!!diagnosis" [diagnosis]="diagnosis">
            </app-additional-parameters-modal>
          </div>
          <div class="row" *ngIf="paramsAvailable(diagnosis)">
            <b
              class="col sm-9 pad-vertical-small pad-horizontal-small additional-parameters-list-title"
            >
              {{ 'DIAGNOSIS.ADDITIONAL_PARAMETERS.TITLE' | translate }}
            </b>

            <ul class="additional-parameters-list">
              <li *ngFor="let additionalParam of diagnosis.additionalParameters">
                <b>{{ additionalParam.name }}</b> - {{ additionalParam.value }}
              </li>
            </ul>
          </div>
          <div *ngIf="diagnosis.events !== null && diagnosis.events !== undefined" class="row">
            <table id="entriesTable" name="entriesTable" class="table">
              <thead>
                <tr>
                  <th class="th_statuscode">
                    <small> {{ 'DIAGNOSIS.TABLE.STATUSCODE' | translate }}</small>
                  </th>
                  <th class="th_usecase">
                    <small> {{ 'DIAGNOSIS.TABLE.USE_CASE' | translate }}</small>
                  </th>
                  <th class="th_action">
                    <small> {{ 'DIAGNOSIS.TABLE.ACTION' | translate }}</small>
                  </th>
                  <th class="th_component">
                    <small> {{ 'DIAGNOSIS.TABLE.COMPONENT' | translate }}</small>
                  </th>
                  <th class="th_sensorid">
                    <small> {{ 'DIAGNOSIS.TABLE.SENSOR_ID' | translate }}</small>
                  </th>
                  <th class="th_traceid">
                    <small> {{ 'DIAGNOSIS.TABLE.TRACE_ID' | translate }}</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let event of diagnosis.events">
                  <td title="{{ event.statusCode }}">
                    <small>{{ event.statusCode }}</small>
                  </td>
                  <td title="{{ event.useCase }}">
                    <small>{{ event.useCase }}</small>
                  </td>
                  <td title="{{ event.action }}">
                    <small>{{ event.action }}</small>
                  </td>
                  <td title="{{ event.component }}">
                    <small>{{ event.component }}</small>
                  </td>
                  <td title="{{ event.sensorId }}">
                    <small>{{ event.sensorId }}</small>
                  </td>
                  <td class="traceid" title="{{ event.traceId }}">
                    <small>
                      <a
                        class="pad-small"
                        href="javascript:void(0)"
                        (click)="navigateToTraceDiagnosis(event)"
                      >
                        {{ event.traceId }}
                      </a>
                    </small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--End-->

        <app-feedback-modal
          (closeModal)="onCloseFeedbackModal()"
          (send)="onFeedbackModalSend($event)"
          *ngIf="!!diagnosisForFeedback"
          [type]="this.feedbackType"
        >
        </app-feedback-modal>
      </div>
    </div>
  </div>
  <div *ngIf="!backendCalled" class="table-footer">
    <div class="pagination" *ngIf="supportsPagination">
      <button
        class="btn btn--primary previous-page"
        (click)="this.handlePreviousPage()"
        [disabled]="this.offset === 0"
      >
        {{ 'DIAGNOSIS.TABLE.PREVIOUS_PAGE' | translate }}
      </button>
      <button
        class="btn btn--primary next-page"
        (click)="this.handleNextPage()"
        [disabled]="isLastPage || backendCalled"
      >
        {{ 'DIAGNOSIS.TABLE.NEXT_PAGE' | translate }}
      </button>
    </div>
    <p *ngIf="supportsPagination">
      {{ 'DIAGNOSIS.TABLE.CURRENT_PAGE' | translate }} {{ currentPageNumber }}
    </p>
    <div class="show-more" *ngIf="isExpandable">
      <button class="btn btn--primary" (click)="handleExpandTable($event)">
        {{ isExpanded ? 'SHOW LESS' : 'SHOW MORE' }}
      </button>
    </div>
  </div>
</div>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexLegend
} from 'ng-apexcharts';
import { FormGroup } from 'ngx-typesafe-forms';
import { CoreService } from 'src/app/shared/interfaces/core-service.interface';
import { DiagnosisForm } from 'src/app/shared/models/diagnosis.model';
import { DiagnosisService } from 'src/app/shared/services/diagnosis.service/diagnosis.service';
import en from 'apexcharts/dist/locales/en.json';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
};

@Component({
  selector: 'app-num-of-hits-chart',
  templateUrl: './num-of-hits-chart.component.html',
  styleUrls: ['./num-of-hits-chart.component.scss']
})
export class NumOfHitsChartComponent<T extends DiagnosisForm> implements OnInit {
  @Input() diagnosisForm: FormGroup<T>;
  @Input() diagnosisService: DiagnosisService<T>;
  @Input() selectedService: CoreService;
  @Input() isChartOpen: boolean = false;
  @Input() chartOpenHandler: Function;

  @Input() numOfResults: number;
  @Input() numOfSkippedResults: number;
  public chartBackendCalled;

  // chart props
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  private isDarkMode: boolean = localStorage.getItem('theme') === 'dark';
  public isZoomed = false;
  public resetZoom = () => this.chart.resetSeries();

  constructor() {
    this.chartOptions = {
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            day: 'dd/MM',
            hour: 'HH:mm'
          },
          datetimeUTC: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
      series: [
        {
          name: 'success',
          data: []
        },
        {
          name: 'info',
          data: []
        },
        {
          name: 'error',
          data: []
        }
      ],
      chart: {
        stacked: true,
        defaultLocale: 'en',
        locales: [en],
        height: 300,
        type: 'bar',
        toolbar: {
          show: false
        },
        fontFamily: 'VWAGTheSans-Regular',
        events: {
          zoomed: () => {
            this.isZoomed = true;
          }
        }
      },
      title: {
        text: 'Num. of hits',
        align: 'center'
      },
      fill: {
        colors: this.isDarkMode ? ['#349CA0'] : ['#004666']
      },
      dataLabels: {
        enabled: false
      }
    };
  }

  toggleIsOpen = () => {
    this.chartOpenHandler && this.chartOpenHandler();
    if (!this.isChartOpen) {
      const pageSize = this.diagnosisForm.getRawValue().pageSize;
      const request = {
        ...this.diagnosisForm.getRawValue(),
        modId: this?.selectedService.modId
      };
      this.chartBackendCalled = true;
      this.diagnosisService.getChartDataTypes(request).subscribe((data) => {
        setTimeout(() => {
          this.chart.updateOptions({
            xaxis: {
              categories: data.range.map((dt) => transformToIsoDateType(dt))
            }
          });
          this.chart.updateSeries(
            data.data.map((item) =>
              Object({
                name: item.type,
                data: item.data,
                color:
                  item.type === 'success'
                    ? '#008000'
                    : item.type === 'error'
                    ? '#da0c1fff'
                    : '#d3caca'
              })
            )
          );
          return data;
        }, pageSize);
        this.chartBackendCalled = false;
      });
    }
  };

  ngOnInit(): void {}
}

const transformToIsoDateType = (dateAsString: string): string => {
  const dt = new Date(dateAsString);
  return dt.toISOString();
};

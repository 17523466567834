<div class="row row--container mar-top-large">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <div class="row">
          <h5>{{ 'HELP.HEADER' | translate }}</h5>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row row--container">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <p>
          <b>{{ 'HELP.SUBHEADER_1' | translate }}</b>
        </p>

        <p>
          {{ 'HELP.AREAS_TEXT' | translate }}
        </p>

        <br />
        <p>
          <b>{{ 'HELP.SUBHEADER_2' | translate }}</b>
        </p>

        <p>
          {{ 'HELP.WHO_TO_QUESTION' | translate }}
        </p>
        <p>
          <a href="mailto:support.e2e.diagnose.vwag.r.wob@volkswagen.de"
            >support.e2e.diagnose.vwag.r.wob&#64;volkswagen.de</a
          >
        </p>
        <p [innerHTML]="'HELP.ALTERNATIVE_SOLUTION' | translate"></p>
        <p>
          <a href="mailto:support.e2e.diagnose.vwag.r.wob@volkswagen.de"
            >support.e2e.diagnose.vwag.r.wob&#64;volkswagen.de</a
          >
        </p>
        <br />
      </div>
    </div>
  </div>
</div>

import { ValidatorFn } from 'ngx-typesafe-forms';
import { Constants } from '../constants';

export const vinPrefixValidator = <T extends { vin: string; brand: string }>(): ValidatorFn<T> => (
  control
) => {
  const { vin, brand } = control.value;

  return vin?.length === 0 || vin === undefined || validateVinPrefix(vin, brand)
    ? null
    : { vinPrefix: true };
};

function validateVinPrefix(vinInput: string, brand: string): boolean {
  return Constants.allowedVins
    .get(brand.toUpperCase())
    .some((vin) => vinInput.toUpperCase().startsWith(vin));
}

/** At least vin or user id has to be filled */
import { ValidatorFn } from 'ngx-typesafe-forms';

export const userAndVinValidator = <T extends { user: string; vin: string }>(
  vinRequiredByServiceSupplier: () => boolean,
  userIdRequiredByServiceSupplier: () => boolean
): ValidatorFn<T> => (control) => {
  const { vin } = control.value;
  const { user } = control.value;
  return vinRequiredByServiceSupplier() && userIdRequiredByServiceSupplier() && (!vin || !user)
    ? { vinAndUser: true }
    : null;
};

export interface CoreService {
  name: string;
  modId: string;
  generation: string;
  vinRequired: boolean;
  userIdRequired: boolean;
  brand: string;
  supportedLanguages: string[];
  serviceDescriptions: Localization;
  type: ServiceType;
}

export enum ServiceType {
  CORE = 'core-service',
  CUSTOMER = 'customer-service',
  CUSTOMIZED = 'customized-service'
}

export interface Localization {
  [language: string]: string;
}

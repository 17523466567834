import { environment as environmentProd } from './environment.prod';
import { environment as environmentPreProd } from './environment.preprod';
import { environment as environmentDev } from './environment.dev';

enum Environment {
  PROD = 'PROD',
  TUI = 'TUI',
  DEV = 'DEV',
  LOCAL = 'LOCAL'
}

interface HostnameConfigPair {
  hostnames: String[];
  configuration: any;
}

const envToHostnameConfigPairMapping: { [id: string]: HostnameConfigPair } = {
  [Environment.PROD]: {
    hostnames: ['e2ed.apps.emea.vwapps.io', 'e2ed-diagnosis-tool.e2ed.vwapps.run'],
    configuration: environmentProd
  },
  [Environment.TUI]: {
    hostnames: ['e2ed-tui.apps.emea.vwapps.io', 'e2ed-diagnosis-tool.e2ed-tui.vwapps.run'],
    configuration: environmentPreProd
  },
  [Environment.DEV]: {
    hostnames: ['e2ed-diagnosis-tool.e2ed-dev.vwapps.run'],
    configuration: environmentDev
  },
  [Environment.LOCAL]: {
    hostnames: ['localhost'],
    configuration: environmentDev
  }
};

const envName = Object.keys(Environment).find((key) =>
  envToHostnameConfigPairMapping[key].hostnames.includes(window.location.hostname)
);

if (!envName) {
  console.log('Hostname unknown, falling back to LOCAL env...');
}

export const environment =
  envToHostnameConfigPairMapping[envName || Environment.LOCAL].configuration;

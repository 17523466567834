import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from 'ngx-typesafe-forms';
import { BaseDiagnosisComponent } from 'src/app/components/diagnosis/base-diagnosis/base-diagnosis.component';
import { UserService } from 'src/app/shared/services/user-service.service';
import { NotificationService } from '../../components/notification/notification.service';
import { env } from '../../environment';
import { Constants, Role } from '../../shared/constants';
import { VinRequestForm } from '../../shared/models/diagnosis.model';
import { VinDiagnosisService } from '../../shared/services/diagnosis.service/diagnosis.service';
import { RolesService } from '../../shared/services/roles-service.service';
import { ServicesService } from '../../shared/services/services.service';
import { patternValidator } from '../../shared/validators/pattern-validator.directive';
import { sizeValidator } from '../../shared/validators/size-validator.directive';
import { userIdValidator } from '../../shared/validators/user-validator.directive';
import { vinValidator } from 'src/app/shared/validators/vin-validator.directive';
import { DiagnosisLanguageService } from '../../shared/services/diagnosis-language.service';
import { vinPrefixValidator } from '../../shared/validators/vin-prefix-validator.directive';
import { HttpClient } from '@angular/common/http';
import { userAndVinValidator } from 'src/app/shared/validators/user-and-vin-validator.directive';
import { userOrVinValidator } from '../../shared/validators/vin-or-user-id-validator.directive';

@Component({
  selector: 'app-customized-services-diagnosis',
  templateUrl: './customized-services-diagnosis.component.html',
  styleUrls: ['./customized-services-diagnosis.component.scss']
})
export class CustomizedServicesDiagnosisComponent extends BaseDiagnosisComponent<VinRequestForm> {
  readonly ALL_ROLES: Role[] = Constants.ALL_ROLES;
  public features = env.features;
  public Role = Role;

  public customizedDiagnoses = null;
  public readonly USER_KEY = 'user';
  public readonly VIN_KEY = 'vin';
  public isChartOpen: boolean = false;

  constructor(
    diagnosisLanguageService: DiagnosisLanguageService,
    servicesService: ServicesService,
    diagnosisService: VinDiagnosisService,
    notificationService: NotificationService,
    userService: UserService,
    rolesService: RolesService,
    http: HttpClient
  ) {
    super(
      diagnosisLanguageService,
      servicesService,
      diagnosisService,
      notificationService,
      userService,
      rolesService,
      http
    );
    // @ts-ignore
    super.diagnoses = null;
  }

  toggleIsChartOpen = () => (this.isChartOpen = !this.isChartOpen);

  onSubmit(): void {
    this.warningMessage = null;
    this.customizedDiagnoses = null;
    this.submitted = true;
    this.diagnosedService = null;

    this.isChartOpen === true && this.toggleIsChartOpen();
    const request = {
      ...this.diagnosisForm.getRawValue(),
      modId: this?.selectedService?.modId
    };

    if (this.diagnosisForm.valid) {
      this.backendCalled = true;
      this.diagnosisService.getCustomizedDiagnosis(request).subscribe(
        (data) => {
          this.totalNumOfResults = data.total;
          if (data?.diagnoses.length > 0) {
            this.customizedDiagnoses = data.diagnoses;
          }
          this.warningMessage = new Map([[, data.message]]);
          this.backendCalled = false;
        },
        (error) => {
          this.backendCalled = false;
          if (error.status === 404 || error.status === 204) {
            this.warningMessage = error.error.errorMessages;
            if (!this.warningMessage) {
              this.warningMessage = new Map([[' ', error.error.message]]);
            }
          }
        }
      );
    }
  }

  protected extendForm(form: FormGroup<VinRequestForm>) {
    form.addControl(
      this.USER_KEY,
      new FormControl(this.diagnosisService.getFormValue().user, [
        this.features.get('emailSearch')
          ? patternValidator([Constants.emailPattern, Constants.userIdPattern])
          : patternValidator([Constants.userIdPattern])
      ])
    );

    form.addControl(
      this.VIN_KEY,
      new FormControl(this.diagnosisService.getFormValue().vin, [sizeValidator(17)])
    );

    form.addValidators([
      userAndVinValidator(
        () => this.selectedService?.vinRequired,
        () => this.selectedService?.userIdRequired
      ),
      userOrVinValidator(
        () => this.selectedService?.vinRequired,
        () => this.selectedService?.userIdRequired
      ),
      userIdValidator(
        () => this.selectedService?.vinRequired,
        () => this.selectedService?.userIdRequired
      ),
      vinValidator(
        () => this.selectedService?.vinRequired,
        () => this.selectedService?.vinRequired
      ),
      vinPrefixValidator()
    ]);
  }
}

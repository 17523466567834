import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisLanguageService {
  private _pickedDiagnosisLanguage: string;
  private _availableDiagnosisLanguages: string[];

  constructor(public translateService: TranslateService) {
    this.pickedDiagnosisLanguage = translateService.currentLang;
  }

  public set pickedDiagnosisLanguage(language: string) {
    if (this.translateService.getLangs().includes(language)) {
      this.translateService.use(language);
    } else {
      this.translateService.use(this.translateService.getDefaultLang());
    }
    this._pickedDiagnosisLanguage = language;
  }

  public get pickedDiagnosisLanguage(): string {
    return this._pickedDiagnosisLanguage;
  }

  public set availableDiagnosisLanguages(languages: string[]) {
    this._availableDiagnosisLanguages = this.translateService
      .getLangs()
      .concat(languages)
      .filter((v, i, a) => a.indexOf(v) === i);
  }
  public get availableDiagnosisLanguages(): string[] {
    return this._availableDiagnosisLanguages;
  }
}

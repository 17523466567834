import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from 'ngx-typesafe-forms';
import { filter, take } from 'rxjs/operators';
import { DiagnosisService } from 'src/app/shared/services/diagnosis.service/diagnosis.service';
import { Constants, Role } from '../../../shared/constants';
import { CoreService, ServiceType } from '../../../shared/interfaces/core-service.interface';
import {
  Diagnosis,
  DiagnosisForm,
  DiagnosisResponse
} from '../../../shared/models/diagnosis.model';
import { RolesService } from '../../../shared/services/roles-service.service';
import { UserService } from '../../../shared/services/user-service.service';
import { ServicesService } from '../../../shared/services/services.service';
import { arrayContainsValidators } from '../../../shared/validators/array-contains-validator.directive';
import {
  dateTimeValidator,
  earliestDateWithTimeBuffer,
  latestDate
} from '../../../shared/validators/time-validator.directive';
import { NotificationService } from '../../notification/notification.service';
import { DiagnosisLanguageService } from '../../../shared/services/diagnosis-language.service';
import { datePickerErrorTransformValidator } from '../../../shared/validators/date-picker-error-transform-validator.directive';

@Component({ template: '' })
export abstract class BaseDiagnosisComponent<T extends DiagnosisForm> implements OnInit {
  public readonly TO_KEY = 'to';
  public readonly FROM_KEY = 'from';
  public readonly MODID_KEY = 'modId';
  public readonly BRAND_KEY = 'brand';
  public readonly ROLE_KEY = 'role';
  public readonly FILTER_TOGGLE_KEY = 'onlyErrors';
  public readonly PAGE_SIZE_KEY = 'pageSize';
  public backendCalled = false;
  public chartBackendCalled = false;
  public isChartOpen = false;

  public currentPageNumber: number = 1;
  public totalNumOfResults: number = 0;
  public totalNumOfSkippedResults: number = 0;

  public selectedService: CoreService;

  readonly ALL_ROLES: Role[] = Constants.ALL_ROLES;
  public brandRoles: Role[];
  services: CoreService[] = [];
  favouriteServicesModIds: string[] = [];
  favouriteServicesToDisplay: CoreService[] = [];
  servicesToDisplay: CoreService[] = [];
  customerServicesToDisplay: CoreService[] = [];
  customizedServices: CoreService[] = [];
  customizedServicesToDisplay: CoreService[] = [];
  diagnoses: Diagnosis[];
  public previousDiagnoses: Diagnosis[] = [];
  public lastRequest: T;
  public diagnosedService: CoreService;
  public eventsToScrollThrough: number = 0;

  public totalDiagnosesReported: number;
  public submitted = false;
  public warningMessage: Map<string, string>;
  public diagnosisForm: FormGroup<T>;
  pageSizeOptions: number[] = [30, 50, 100, 400];

  public currentScrollId: string;
  public isLastPage: boolean;
  public offset: number = Constants.DEFAULT_OFFSET;

  opened: boolean;

  constructor(
    private diagnosisLanguageService: DiagnosisLanguageService,
    private servicesService: ServicesService,
    public diagnosisService: DiagnosisService<T>,
    private notificationService: NotificationService,
    private userService: UserService,
    public rolesService: RolesService,
    public http: HttpClient
  ) {
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.errorHandlingOnDiagnosis = this.errorHandlingOnDiagnosis.bind(this);
    this.skipDetection = this.skipDetection.bind(this);
  }

  toggleIsChartOpen = () => (this.isChartOpen = !this.isChartOpen);

  protected abstract extendForm(form: FormGroup<T>);

  onChangeFrom(value) {
    if (value.minDateTime > value._selected) {
      this.diagnosisForm.controls.from.setValue(value.minDateTime);
    } else {
      this.diagnosisForm.controls.from.setValue(value._selected);
    }
  }

  onChangeTo(value) {
    this.diagnosisForm.controls.to.setValue(value._selected);
  }

  handleServiceChange = (service: CoreService) => {
    this.selectedService = service;
  };

  handlePreviousPage() {
    const { pageSize } = this.lastRequest;
    this.diagnoses = [];
    this.offset = this.offset - pageSize;
    if (this.offset <= 0) {
      this.offset = 0;
    }

    this.diagnoses = this.previousDiagnoses.slice(this.offset, this.offset + pageSize);
    this.isLastPage = this.detectLastPageWithoutRequest();
    this.currentPageNumber = this.currentPageNumber - 1;

    this.scrollToTop();
    this.isChartOpen === true && this.toggleIsChartOpen();
  }

  public handleNextPage() {
    const pageSize = this.lastRequest.pageSize;

    this.offset = Math.min(this.offset + pageSize, this.previousDiagnoses.length);
    this.diagnoses = null;
    this.warningMessage = null;

    if (this.offset === this.previousDiagnoses.length) {
      this.backendCalled = true;
      this.diagnosisService
        .getDiagnosis(this.lastRequest, this.currentScrollId)
        .subscribe((data) => {
          if (data.diagnoses.length > 0) {
            this.diagnoses = data.diagnoses;
          } else {
            this.diagnoses = this.previousDiagnoses.slice(this.offset);
          }

          this.totalDiagnosesReported -= data.skipped;
          this.previousDiagnoses = [...this.previousDiagnoses, ...data.diagnoses];
          this.currentScrollId = data.scrollId;
          this.backendCalled = false;
          this.isLastPage = this.detectLastPage(data);
          this.skipDetection(data);
          this.scrollToTop();
        }, this.errorHandlingOnDiagnosis);
    } else {
      this.diagnoses = this.previousDiagnoses.slice(this.offset, this.offset + pageSize);
      this.isLastPage = this.detectLastPageWithoutRequest();
      this.scrollToTop();
    }

    this.currentPageNumber = this.offset / pageSize + 1;

    this.isChartOpen === true && this.toggleIsChartOpen();
  }

  private detectLastPage(data: DiagnosisResponse) {
    if (this.diagnosedService.generation === 'MOD 3') {
      return !data.scrollId;
    } else {
      return this.previousDiagnoses.length === this.totalDiagnosesReported;
    }
  }

  private detectLastPageWithoutRequest() {
    if (this.diagnosedService.generation === 'MOD 3') {
      return (
        this.offset + this.lastRequest.pageSize >= this.previousDiagnoses.length &&
        !this.currentScrollId
      );
    } else {
      return (
        this.offset + this.lastRequest.pageSize >= this.previousDiagnoses.length &&
        this.previousDiagnoses.length === this.totalDiagnosesReported
      );
    }
  }

  scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  vinOrUserValidationHasErrors() {
    return this.diagnosisForm.hasError('vinOrUser') ||
      this.diagnosisForm.hasError('vinAndUser') ||
      this.diagnosisForm.hasError('vinRequired') ||
      this.diagnosisForm.hasError('userIdRequired') ||
      this.diagnosisForm.hasError('vinPrefix')
      ? true
      : null;
  }

  servicesAreEmpty() {
    return this.servicesToDisplay.length === 0 && this.customerServicesToDisplay.length === 0
      ? true
      : null;
  }

  onSubmit() {
    this.offset = 0;
    this.warningMessage = null;
    this.diagnoses = null;
    this.submitted = true;
    this.previousDiagnoses = [];
    this.isChartOpen = false;
    this.diagnosedService = null;
    this.currentPageNumber = 1;

    const request = {
      ...this.diagnosisForm.getRawValue(),
      modId: this?.selectedService?.modId
    };

    this.lastRequest = { ...request };
    this.diagnosedService = this.selectedService;

    if (this.diagnosisForm.valid) {
      this.backendCalled = true;
      this.diagnosisService.getDiagnosis(request).subscribe((data: DiagnosisResponse) => {
        this.backendCalled = false;
        this.totalDiagnosesReported = data.total - data.skipped;
        this.previousDiagnoses = [...this.previousDiagnoses, ...data.diagnoses];
        this.currentScrollId = data.scrollId;
        this.diagnoses = data.diagnoses;
        this.eventsToScrollThrough = data.total - data.skipped - data.diagnoses.length;
        this.totalNumOfResults = data.total;
        this.totalNumOfSkippedResults = data.skipped;

        this.isLastPage = this.detectLastPage(data);
        this.skipDetection(data);
      }, this.errorHandlingOnDiagnosis);
    }
  }

  private errorHandlingOnDiagnosis(error: HttpErrorResponse) {
    this.backendCalled = false;
    this.isLastPage = true;
    if (error.status === 404) {
      this.warningMessage = error.error.errorMessages;
      if (!this.warningMessage) {
        this.warningMessage = new Map([[' ', error.error.message]]);
      }

      this.addNonWhitelistedWarningIfTesterRoleExecutor();
    } else {
      console.error(error);

      const requestId = error.headers.has('e2ed-requestId')
        ? ' requestId ' + error.headers.get('e2ed-requestId')
        : '';

      this.notificationService.error('ERROR' + requestId, error.error.message);
    }
  }

  private addNonWhitelistedWarningIfTesterRoleExecutor() {
    if (this.lastRequest.role !== Role.TESTER) {
      return;
    }
    this.warningMessage['TESTER'] = 'DIAGNOSIS.WARNING.TESTER_MAYBE_NOT_WHITELISTED_VIN';
  }

  ngOnInit() {
    const {
      from,
      to,
      modId,
      brand,
      role,
      onlyErrors: onlyFailures,
      pageSize
    } = this.diagnosisService.getFormValue();
    // @ts-ignore 'modId', 'lang', 'from' and 'to' have to be in the form
    this.diagnosisForm = new FormGroup<T>({
      [this.FROM_KEY]: new FormControl(from, [dateTimeValidator()]),
      [this.TO_KEY]: new FormControl(to, [dateTimeValidator()]),
      [this.MODID_KEY]: new FormControl(modId),
      [this.BRAND_KEY]: new FormControl(brand, [
        arrayContainsValidators(this.rolesService.getBrandsOfUser())
      ]),
      [this.ROLE_KEY]: new FormControl(role, [arrayContainsValidators(this.ALL_ROLES)]),
      [this.FILTER_TOGGLE_KEY]: new FormControl(onlyFailures),
      [this.PAGE_SIZE_KEY]: new FormControl(pageSize)
    });

    this.diagnosisForm.addValidators([datePickerErrorTransformValidator(this.diagnosisForm)]);

    this.changeRolesToDisplay(brand);

    this.extendForm(this.diagnosisForm);

    this.userService
      .getUser()
      .pipe(
        filter((val) => val !== null),
        take(1)
      )
      .subscribe(() => {
        this.getServices();
        this.getCustomizedServices();
      });

    // TODO: Refactor to support customized services (IF NEEDED)
    // this.diagnoses = this.diagnosisService.getCachedDiagnosis()?.diagnoses;
  }

  private getCustomizedServices() {
    this.servicesService.getCustomizedServices().subscribe((result) => {
      this.customizedServices = result;
      this.filterVisibleServices(this.diagnosisService.getFormValue().brand, false);
    });
  }

  private getServices() {
    this.servicesService.getCoreServices().subscribe((result) => {
      this.servicesService.getFavouriteServices().subscribe((favServicesResult) => {
        this.services = result;
        this.favouriteServicesModIds = favServicesResult;
        this.filterVisibleServices(this.diagnosisService.getFormValue().brand, false);
        const supportedLanguages = []
          .concat(...this.services.map((service) => service.supportedLanguages))
          .filter((v, i, a) => a.indexOf(v) === i);
        this.diagnosisLanguageService.availableDiagnosisLanguages = supportedLanguages;
      });
    });
  }

  earliestDate() {
    return earliestDateWithTimeBuffer();
  }

  latestDate() {
    return latestDate();
  }

  changeBrandValue(brand: string) {
    this.diagnosisForm.controls.brand.setValue(brand);
    this.changeRolesToDisplay(brand);
    this.filterVisibleServices(brand, true);
  }

  changeFormRoleValue(role: Role) {
    this.rolesService.onRoleChange.emit({
      brand: this.diagnosisForm.controls.brand.value,
      role
    });
    this.diagnosisForm.controls.role.setValue(role);
  }

  getServicesWithTitle(services: CoreService[]) {
    return services.map((value) => {
      let serviceName = value.name;
      const generation = value.generation.replace(/\s/g, '');
      if (!serviceName.match('^MOD(?:\\s\\d|[0-9])?:?\\s\\S.*')) {
        serviceName = generation + ': ' + serviceName;
      } else {
        const nameAfterModId = serviceName.substring(serviceName.indexOf(':') + 1).trim();
        serviceName = generation + ': ' + nameAfterModId;
      }

      return { ...value, name: serviceName };
    });
  }

  getDefaultSelectedService(isCustomized: boolean, brand: string): CoreService {
    const cookieModId = DiagnosisService.getCookie('modId');

    if (!!cookieModId) {
      const allServices = [
        ...this.favouriteServicesToDisplay,
        ...this.customizedServicesToDisplay,
        ...this.servicesToDisplay
      ];
      const cookieService = allServices.find((service) => service.modId === cookieModId);
      if (!!cookieService) {
        return cookieService;
      }
    }

    if (isCustomized) {
      const favouriteCustomizedService = this.favouriteServicesToDisplay.find(
        (service) => service.type === ServiceType.CUSTOMIZED && service.brand === brand
      );
      return favouriteCustomizedService
        ? favouriteCustomizedService
        : this.customizedServicesToDisplay[0];
    }
    if (this.favouriteServicesToDisplay.length > 0) {
      return this.favouriteServicesToDisplay[0];
    }
    if (this.customerServicesToDisplay.length > 0) {
      return this.customerServicesToDisplay[0];
    }
    if (this.servicesToDisplay.length > 0) {
      return this.servicesToDisplay[0];
    }
  }

  filterVisibleServices(brand: string, resetControl: boolean) {
    const isCustomized = window.location.href.includes('customized-services-diagnosis');
    const allServices = [...this.services, ...this.customizedServices];

    const servicesWithoutFavourites = allServices.filter(
      (service) => !this.favouriteServicesModIds.includes(service.modId)
    );

    const servicesWithTitle = this.getServicesWithTitle(servicesWithoutFavourites);

    // sort services by favourite services
    const foundFavServices = allServices.filter((service) =>
      this.favouriteServicesModIds.includes(service.modId)
    );

    const sortServices = (array, sortArray) => {
      return [...array].sort((a, b) => sortArray.indexOf(a.modId) - sortArray.indexOf(b.modId));
    };

    const sortedServices = sortServices(foundFavServices, this.favouriteServicesModIds);
    const brandServices = sortedServices.filter((service) => service.brand === brand);
    const favouriteServices = brandServices.filter(
      (value) => value.type === ServiceType.CORE || value.type === ServiceType.CUSTOMER
    );
    const favouriteCustomizedServices = brandServices.filter(
      (value) => value.type === ServiceType.CUSTOMIZED
    );
    this.favouriteServicesToDisplay = isCustomized
      ? favouriteCustomizedServices
      : favouriteServices;

    this.servicesToDisplay = servicesWithTitle.filter(
      (value) => value.brand === brand && value.type === ServiceType.CORE
    );
    this.customerServicesToDisplay = servicesWithTitle.filter(
      (value) => value.brand === brand && value.type === ServiceType.CUSTOMER
    );
    this.customizedServicesToDisplay = servicesWithTitle.filter(
      (value) => value.brand === brand && value.type === ServiceType.CUSTOMIZED
    );

    this.groupServicesByNameDesc(this.servicesToDisplay);
    this.groupServicesByNameDesc(this.customerServicesToDisplay);
    this.groupServicesByNameDesc(this.customizedServicesToDisplay);

    let modIndices = new Map<string, number>();
    this.buildModIdsMap(modIndices, this.servicesToDisplay);
    this.sortServicesByName(this.servicesToDisplay, modIndices);

    modIndices = new Map<string, number>();
    this.buildModIdsMap(modIndices, this.customerServicesToDisplay);
    this.sortServicesByName(this.customerServicesToDisplay, modIndices);

    modIndices = new Map<string, number>();
    this.buildModIdsMap(modIndices, this.customizedServicesToDisplay);
    this.sortServicesByName(this.customizedServicesToDisplay, modIndices);

    const modId =
      (!resetControl && this.diagnosisService.getFormValue().modId) ||
      this.customerServicesToDisplay[0]?.modId ||
      this.servicesToDisplay[0]?.modId;

    this.selectedService = this.getDefaultSelectedService(isCustomized, brand);

    this.diagnosisForm.setControl(
      // @ts-ignore modId will always be inside of the request
      this.MODID_KEY,
      new FormControl(modId)
    );

    if (this.servicesToDisplay.length === 0 && this.customerServicesToDisplay.length === 0) {
      this.warningMessage = new Map([[' ', 'DIAGNOSIS.WARNING.NO_SERVICES']]);
      this.diagnosisForm.disable();
    } else {
      this.warningMessage = null;
      this.diagnosisForm.enable();
    }
  }

  changeRolesToDisplay(brand: string) {
    this.brandRoles = this.rolesService.getMappedRolesForBrand(brand);
    this.changeFormRoleValue(this.brandRoles[0]);
  }

  getRoleToDisplay(role: Role): Role {
    if (this.checkIfProductionSelected() && role === Role.COMPETENCE_CENTER) {
      return Role.INSPECTOR;
    }
    return role;
  }

  private checkIfProductionSelected(): boolean {
    return this.diagnosisForm.controls.brand.value === Constants.BRAND_PRODUCTION;
  }

  private buildModIdsMap(modIndices: Map<string, number>, services: CoreService[]) {
    let counter = 0;
    for (let i = 0; i < services.length; i++) {
      modIndices.set(services[i].name.charAt(3), ++counter);
      if (
        i < services.length - 1 &&
        services[i].name.charAt(3) !== services[i + 1].name.charAt(3)
      ) {
        counter = 0;
      }
    }
  }

  private groupServicesByNameDesc(services: CoreService[]) {
    services.sort((a, b) => {
      if (a.name.charAt(3) > b.name.charAt(3)) {
        return -1;
      }
    });
  }

  private sortServicesByName(servicesToDisplay: CoreService[], modIndices: Map<string, number>) {
    let start = 0;
    let end = 0;
    for (let i = 0; i < modIndices.size; i++) {
      let step = modIndices.get(servicesToDisplay[start].name.charAt(3));
      if (start < servicesToDisplay.length) {
        end = start + step;
        const toSort = servicesToDisplay.slice(start, end);
        this.sortAlphabetically(toSort);
        servicesToDisplay.splice(start, step, ...toSort);
        start = end;
      }
    }
  }

  private sortAlphabetically(services: CoreService[]) {
    services.sort((a, b) => {
      const firstName = a.name.toLowerCase().substring(6);
      const secondName = b.name.toLowerCase().substring(6);
      if (firstName > secondName) {
        return 1;
      } else if (firstName === secondName) {
        return 0;
      } else {
        return -1;
      }
    });
  }

  private skipDetection(data: DiagnosisResponse) {
    if (!data.skipped) {
      return;
    }

    if (this.isLastPage) {
      if (this.previousDiagnoses.length) {
        this.warningMessage = new Map([
          [this.diagnosedService.name, 'DIAGNOSIS.WARNING.ALL_EVENTS_READ']
        ]);
      } else {
        this.warningMessage = new Map([[this.diagnosedService.name, 'DIAGNOSIS.ERROR.NOT_FOUND']]);
      }
    } else {
      this.warningMessage = new Map([
        [this.diagnosedService.name, 'DIAGNOSIS.WARNING.SOME_EVENTS_FILTERED']
      ]);
    }
  }
}

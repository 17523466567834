import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/shared/constants';
import { TraceDiagnosisService } from 'src/app/shared/services/diagnosis.service/diagnosis.service';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { env } from '../../environment';
import { Diagnosis, DiagnosisForm, SensorEvent } from '../../shared/models/diagnosis.model';
import { NotificationService } from '../notification/notification.service';

@Component({
  selector: 'app-diagnosis-table',
  templateUrl: './diagnosis-table.component.html',
  styleUrls: ['./diagnosis-table.component.scss']
})
export class DiagnosisTableComponent implements OnInit, OnChanges {
  @Input() diagnoses: Diagnosis[];
  @Input() diagnosisForm: DiagnosisForm;
  @Input() diagnosisRequestParams: object;
  @Input() handleNextPage: Function;
  @Input() handlePreviousPage: Function;
  @Input() currentScrollId: string;
  @Input() previousDiagnoses: Diagnosis[];
  @Input() offset: number = Constants.DEFAULT_OFFSET;
  @Input() isLastPage: boolean;
  @Input() backendCalled: boolean;
  @Input() currentPageNumber: number;
  @Input() totalNumOfResults: number;
  @Input() totalNumOfSkippedResults: number;

  @Input() supportsPagination: boolean = true;

  @Input() isExpandable: boolean = false;
  @Input() initialResultCount: number = 1;

  isExpanded: boolean = this.isExpandable;

  dateTimeFormat: string;
  diagnosisForFeedback: Diagnosis = null;
  feedbackType: 'POSITIVE' | 'NEGATIVE' = null;

  constructor(
    private traceDiagnosisService: TraceDiagnosisService,
    private router: Router,
    private feedbackService: FeedbackService,
    private notificationService: NotificationService
  ) {
    this.dateTimeFormat = env.dateTimeFormat;
  }

  diagnosesToShow: Diagnosis[] = [];

  handleExpandTable = (event: Event) => {
    event.preventDefault();
    this.isExpanded = !this.isExpanded;
    this.diagnosesToShow = this.isExpanded
      ? this.diagnoses
      : this.diagnoses.slice(0, this.initialResultCount);
  };

  ngOnInit() {
    this.diagnosesToShow = this.isExpandable
      ? this.diagnoses.slice(0, this.initialResultCount)
      : this.diagnoses;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.diagnosis) {
    }
    if (changes?.currentPageNumber) {
      this.diagnosesToShow = this.diagnoses;
    }
    const isWap = changes?.diagnoses?.currentValue[0].modId === 'WAP';
    if (isWap) {
      const missingAdditionalParams: string[] = [];
      const hasWebAppList = changes?.diagnoses?.currentValue[0]?.additionalParameters.find(
        (additionalParam) => additionalParam.name === 'Webapp List'
      );

      if (!hasWebAppList) missingAdditionalParams.push('Webapp List');

      if (missingAdditionalParams.length > 0) {
        this.notificationService.error(
          'Missing additional parameters:',
          `${missingAdditionalParams.join(', ')}`
        );
      }
    }
  }

  toggleIsExpanded = () => (this.isExpanded = !this.isExpanded);

  navigateToTraceDiagnosis(sensorEntry: SensorEvent) {
    const { traceId } = sensorEntry;

    const { brand, from, to, modId, role, onlyErrors: onlyFailures, pageSize } = this.diagnosisForm;

    this.traceDiagnosisService.setFormParams({
      brand,
      from,
      to,
      modId,
      traceId,
      role,
      onlyErrors: onlyFailures,
      pageSize
    });

    this.router.navigateByUrl('/vin-diagnosis', { skipLocationChange: true });
    this.router.navigateByUrl('/traceid-diagnosis');
  }

  onFeedbackButtonClick(type: 'POSITIVE' | 'NEGATIVE', diagnosis: Diagnosis) {
    this.diagnosisForFeedback = diagnosis;
    this.feedbackType = type;
  }

  onFeedbackModalSend(feedback: string) {
    const { brand } = this.diagnosisForm;

    this.feedbackService
      .sendFeedback(
        this.feedbackType,
        brand,
        this.diagnosisForFeedback,
        feedback,
        this.diagnosisRequestParams
      )
      .subscribe(() => {
        this.notificationService.success('DIAGNOSIS.FEEDBACK.THANK_YOU', null);
        this.onCloseFeedbackModal();
      });
  }

  onCloseFeedbackModal() {
    this.diagnosisForFeedback = null;
    this.feedbackType = null;
  }

  paramsAvailable(diagnosis) {
    const emptyParams = diagnosis.additionalParameters.filter(
      (param) => !!param.value || param.value.length > 0
    );
    if (emptyParams.length === 0) {
      return false;
    }

    return !diagnosis.isTableView && diagnosis && diagnosis.additionalParameters.length > 0;
  }
}

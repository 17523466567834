import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Constants } from '../../constants';
import {
  CustomizedDiagnosisHttpResponse,
  CustomizedDiagnosisResponse,
  DiagnosisChartDataTypesResponse,
  DiagnosisChartHttpResponse,
  DiagnosisForm,
  DiagnosisHttpResponse,
  DiagnosisRequest,
  DiagnosisResponse,
  TraceIdRequest,
  TraceRequestForm,
  VinRequest,
  VinRequestForm
} from '../../models/diagnosis.model';
import { RolesService } from '../roles-service.service';
import { DiagnosisLanguageService } from '../diagnosis-language.service';

export abstract class DiagnosisService<
  T extends DiagnosisForm,
  R extends DiagnosisRequest = DiagnosisRequest
> {
  protected formCache: T;
  private resultsCache: DiagnosisResponse = null;
  private requestParamsCache: object = null;

  protected constructor(
    private http: HttpClient,
    private diagnosisLanguageService: DiagnosisLanguageService,
    private rolesService: RolesService,
    initialFormValues: Omit<T, keyof DiagnosisForm>
  ) {
    const initialFormStartDate = new Date();
    initialFormStartDate.setDate(initialFormStartDate.getDate() - 1);

    // @ts-ignore incoming initialFormValues must contain every property that's present in T, but missing in DiagnosisForm
    this.formCache = {
      ...initialFormValues,
      brand: this.calcInitialBrand(),
      from: initialFormStartDate,
      modId: null,
      to: new Date(),
      role: rolesService.getMappedRolesForBrand(this.rolesService.getBrandsOfUser()[0])[0],
      onlyErrors: false,
      pageSize: Constants.DEFAULT_PAGE_SIZE
    };

    DiagnosisService.removeCookie('forwarded_brand');
  }

  public getDiagnosis(form: T): Observable<DiagnosisResponse> {
    this.formCache = form;

    const headers = this.getHttpOptions(this.diagnosisLanguageService.pickedDiagnosisLanguage);
    const request = this.mapRequest(form);
    const params = this.skipEmptyParams({
      ...request,
      from: request.from.toISOString(),
      to: request.to.toISOString(),
      pageSize: request.pageSize.toString(),
      currentPage: request.currentPage
    });

    //@ts-ignore
    return this.http
      .post<DiagnosisHttpResponse>(Constants.DIAGNOSIS_URL, params, {
        headers,
        observe: 'response'
      })
      .pipe(
        catchError((err) => {
          this.resultsCache = null;
          console.error(err);
          return throwError(err);
        })
      )
      .pipe(map((val) => this.mapResponse(val)))
      .pipe(
        tap((x) => {
          // @ts-ignore
          this.resultsCache = x;
          this.requestParamsCache = params;
        })
      );
  }

  public getCustomizedDiagnosis(form: T): Observable<CustomizedDiagnosisResponse> {
    this.formCache = form;

    const headers = this.getHttpOptions(this.diagnosisLanguageService.pickedDiagnosisLanguage);
    const request = this.mapRequest(form);
    const params = this.skipEmptyParams({
      ...request,
      from: request.from.toISOString(),
      to: request.to.toISOString(),
      pageSize: request.pageSize.toString(),
      currentPage: request.currentPage
    });

    // @ts-ignore
    return this.http
      .post<CustomizedDiagnosisHttpResponse>(Constants.CUSTOMIZED_SERVICE_DIAGNOSIS_URL, params, {
        headers,
        observe: 'response'
      })
      .pipe(
        catchError((err) => {
          this.resultsCache = null;
          console.error(err);
          return throwError(err);
        })
      )
      .pipe(map((val) => this.mapCustomizedResponse(val)))
      .pipe(
        tap((x) => {
          // @ts-ignore
          this.resultsCache = x;
          this.requestParamsCache = params;
        })
      );
  }

  public getChartDataTypes(form: T) {
    this.formCache = form;

    const headers = this.getHttpOptions(this.diagnosisLanguageService.pickedDiagnosisLanguage);
    const request = this.mapRequest(form);
    const params = this.skipEmptyParams({
      ...request,
      from: request.from.toISOString(),
      to: request.to.toISOString(),
      pageSize: 10000 // this is customized and can be increased
    });

    return this.http
      .post<DiagnosisChartHttpResponse>(Constants.CHART_DIAGNOSIS_RESPONSE_TYPES, params, {
        headers,
        observe: 'response'
      })
      .pipe(
        catchError((err) => {
          this.resultsCache = null;
          console.error(err);
          return throwError(err);
        })
      )
      .pipe(
        map((val) => {
          // @ts-ignore
          return this.mapChartTypeResponse(val);
        })
      );
  }

  public getCachedDiagnosis(): DiagnosisResponse {
    return { ...this.resultsCache };
  }

  // gets initial or cached form value
  public getFormValue(): T {
    return { ...this.formCache };
  }

  public getCachedRequestParams(): object {
    return { ...this.requestParamsCache };
  }

  protected abstract mapRequest(form: T): R;

  private calcInitialBrand() {
    const initialBrandFromCookie = DiagnosisService.getCookie('forwarded_brand');

    let indexOfBrandInList = this.rolesService
      .getBrandsOfUser()
      .findIndex((b) => b === initialBrandFromCookie);
    if (indexOfBrandInList < 0) {
      indexOfBrandInList = 0;
    }
    return this.rolesService.getBrandsOfUser()[indexOfBrandInList];
  }

  private mapResponse(response: HttpResponse<DiagnosisHttpResponse>): DiagnosisResponse {
    const totalResults = parseInt(response.headers.get('X-E2ED-TOTAL'), 10) || 0;
    const skippedResults = response.body.skipped;

    return {
      diagnoses: response.body.result.map((data) => ({
        ...data,
        eventTime: new Date(data.eventTime),
        failureType: data.failureType,
        additionalParameters: data.additionalParameters || [],
        isTableView: data.modId === 'WAP',
        events:
          data.events &&
          data.events.map((entry) => ({
            ...entry,
            eventTime: new Date(entry.eventTime),
            additionalParameters: entry.additionalParameters || []
          }))
      })),
      total: totalResults,
      skipped: skippedResults
    };
  }

  private mapCustomizedResponse(response: HttpResponse<CustomizedDiagnosisHttpResponse>) {
    const totalResults = parseInt(response.headers.get('X-E2ED-TOTAL'), 10) || 0;
    return {
      resultType: response.body.resultType,
      total: totalResults,
      message: response.body.message,
      diagnoses: response.body.diagnosis.diagnoses.map((data) => {
        return {
          ...data,
          eventTime: new Date(data.eventTime),
          failureType: data.failureType,
          additionalParameters: data.additionalParameters || [],
          isTableView: data.modId === 'WAP',
          events:
            data.events &&
            data.events.map((entry) => ({
              ...entry,
              eventTime: new Date(entry.eventTime),
              additionalParameters: entry.additionalParameters || []
            }))
        };
      })
    };
  }

  private mapChartResponse(response: HttpResponse<DiagnosisChartHttpResponse>) {
    const mappedResponse = Object.keys(response.body).map(function (key) {
      return { x: key, y: response.body[key] };
    });

    return mappedResponse;
  }

  private mapChartTypeResponse(response: HttpResponse<DiagnosisChartDataTypesResponse>) {
    const body = response.body;

    if (!body || !Array.isArray(body.data) || !Array.isArray(body.range)) {
      throw new Error('Unexpected response format');
    }

    const mappedResponse: DiagnosisChartDataTypesResponse = {
      data: body.data.map((item) => ({
        data: item.data,
        type: item.type
      })),
      range: [...body.range],
      total: body.total
    };

    return mappedResponse;
  }

  private getHttpOptions(lang: string) {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept-Language': lang
    });
    return headers;
  }

  private skipEmptyParams = <X>(params: { [ss in keyof X]: X[ss] }) =>
    Object.entries(params).reduce(
      (acc, curr) => (curr[1] ? { ...acc, [curr[0]]: curr[1] } : acc),
      {} as X
    );

  static getCookie(name) {
    return (
      (name = (document.cookie + ';').match(new RegExp(name + '=.*;'))) && name[0].split(/=|;/)[1]
    );
  }

  static removeCookie(name) {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TraceDiagnosisService extends DiagnosisService<TraceRequestForm, TraceIdRequest> {
  constructor(
    http: HttpClient,
    diagnosisLanguageService: DiagnosisLanguageService,
    rolesService: RolesService
  ) {
    super(http, diagnosisLanguageService, rolesService, { traceId: '' });
  }

  public setFormParams(form: TraceRequestForm) {
    this.formCache = form;
  }

  protected mapRequest(form: TraceRequestForm): TraceIdRequest {
    const traceIdRequest: TraceIdRequest = {
      ...form,
      resultFilter: form.onlyErrors ? 'ONLY_ERRORS' : 'ALL'
    };

    return traceIdRequest;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VinDiagnosisService extends DiagnosisService<VinRequestForm, VinRequest> {
  constructor(
    http: HttpClient,
    diagnosisLanguageService: DiagnosisLanguageService,
    rolesService: RolesService
  ) {
    // ETED-1325 Transfer GET to POST request.
    // these cookies are set by the backend
    super(http, diagnosisLanguageService, rolesService, {
      user: DiagnosisService.getCookie('forwarded_userId') || '',
      vin: DiagnosisService.getCookie('forwarded_vin') || ''
    });

    // DiagnosisService.removeCookie('forwarded_userId');
    // DiagnosisService.removeCookie('forwarded_vin');
  }

  protected mapRequest(form: VinRequestForm): VinRequest {
    return {
      from: form.from,
      to: form.to,
      modId: form.modId,
      vin: form.vin,
      brand: form.brand,
      userId: this.checkIfAppropriateValue(Constants.userIdPattern, form.user),
      userEmail: this.checkIfAppropriateValue(Constants.emailPattern, form.user),
      role: form.role,
      resultFilter: form.onlyErrors ? 'ONLY_ERRORS' : 'ALL',
      pageSize: form.pageSize,
      currentPage: form.currentPage
    };
  }

  private checkIfAppropriateValue(regExp: RegExp, value: string): any {
    return regExp.test(value) ? value : null;
  }
}

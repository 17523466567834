import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-less-wrapper',
  templateUrl: './more-less-wrapper.component.html',
  styleUrls: ['./more-less-wrapper.component.scss']
})
export class MoreLessWrapperComponent implements OnInit, AfterViewInit {
  showMore = false;
  displayToggleButton = false;
  @Input() resizableReferences: HTMLElement[] = [];
  @Input() roundOnWithTolerance = true;
  @Input() toleranceHeightInPixels = 50;
  private oldHeightStyle: string[];

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.oldHeightStyle = Array(this.resizableReferences.length);
  }

  ngAfterViewInit() {
    this.adjustHeights();
    this.displayToggleButton = this.hiddenComponentExists();
    this.cdRef.detectChanges();
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
    if (this.showMore) {
      this.fitContent();
    } else {
      this.revertToOldHeight();
    }
  }

  private hiddenComponentExists() {
    let hiddenExists = false;
    for (const el of this.resizableReferences) {
      if (this.toleranceHeightInPixels) {
        if (el.scrollHeight - el.offsetHeight < this.toleranceHeightInPixels) {
          continue;
        }
      } else {
        if (el.offsetHeight >= el.scrollHeight) {
          continue;
        }
      }
      hiddenExists = true;
      break;
    }
    return hiddenExists;
  }

  private applyToAllResizable(fn: (el: HTMLElement, elIndex: number) => void) {
    for (let i = 0; i < this.resizableReferences.length; i++) {
      fn(this.resizableReferences[i], i);
    }
  }

  private fitContent() {
    this.applyToAllResizable((el, i) => {
      this.oldHeightStyle[i] = el.style.getPropertyValue('height');
      // Set height to the scrollHeight due to transition which is available only for fixed values
      el.style.setProperty('height', el.scrollHeight + 'px');
    });
  }

  private adjustHeights() {
    if (!this.roundOnWithTolerance) {
      return;
    }
    this.applyToAllResizable((el, i) => {
      if (el.scrollHeight - el.offsetHeight < this.toleranceHeightInPixels) {
        el.style.setProperty('height', el.scrollHeight + 'px');
      }
    });
  }

  private revertToOldHeight() {
    this.applyToAllResizable((el, i) => {
      el.style.setProperty('height', this.oldHeightStyle[i]);
    });
  }
}

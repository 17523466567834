export const environment = {
  production: true,
  e2edEnv: {
    issuer: 'https://idp.cloud.vwgroup.com/auth/realms/kums-mfa',
    clientId: 'idp-2506fece-fca6-4a0c-853b-1ed1750c253c-E2ED-prod',
    regions: ['en', 'de'],
    dateTimeFormat: 'dd-MM-yyyy HH:mm',
    dateFormat: 'dd-MM-yyyy',
    features: {
      emailSearch: true
    },
    roles: {
      VWAG: {
        COMPETENCECENTER: [
          'VWAG_E2ED_COMPETENCECENTER',
          '0002038977',
          '0002039013',
          '0002038979',
          '0002039012',
          'VWAG_E2ED_ADMIN'
        ],
        CUSTOMERCARE: ['VWAG_E2ED_CUSTOMERCARE', '0002038976', '0002039014', 'VWAG_E2ED_ADMIN'],
        WORKSHOP: ['VWAG_E2ED_WORKSHOP', 'VWAG_E2ED_ADMIN'],
        CUSTOMER: ['VWAG_E2ED_CUSTOMER', 'VWAG_E2ED_ADMIN'],
        TESTER: ['VWAG_E2ED_TESTER', '0002038978', 'VWAG_E2ED_ADMIN']
      },
      SEAT: {
        COMPETENCECENTER: ['SEAT_E2ED_COMPETENCECENTER', '0002038988', 'SEAT_E2ED_ADMIN'],
        CUSTOMERCARE: ['SEAT_E2ED_CUSTOMERCARE', '0002038987', 'SEAT_E2ED_ADMIN'],
        WORKSHOP: ['SEAT_E2ED_WORKSHOP', 'SEAT_E2ED_ADMIN'],
        CUSTOMER: ['SEAT_E2ED_CUSTOMER', 'SEAT_E2ED_ADMIN'],
        TESTER: ['SEAT_E2ED_TESTER', '0002038989', 'SEAT_E2ED_ADMIN']
      },
      PRODUCTION: {
        COMPETENCECENTER: ['VWAG_E2ED_INTERN_INSPECTOR']
      },
      TEST: {
        COMPETENCECENTER: ['VWAG_E2ED_TEST_COMPETENCECENTER', 'VWAG_E2ED_TEST_ADMIN'],
        CUSTOMERCARE: ['VWAG_E2ED_TEST_CUSTOMERCARE', 'VWAG_E2ED_TEST_ADMIN'],
        WORKSHOP: ['VWAG_E2ED_TEST_CUSTOMERCARE', 'VWAG_E2ED_TEST_ADMIN'],
        CUSTOMER: ['VWAG_E2ED_TEST_CUSTOMERCARE', 'VWAG_E2ED_TEST_ADMIN']
      }
    }
  }
};

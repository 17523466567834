import { ValidatorFn } from 'ngx-typesafe-forms';

export const userOrVinValidator = <T extends { user: string; vin: string }>(
  vinRequiredByServiceSupplier: () => boolean,
  userIdRequiredByServiceSupplier: () => boolean
): ValidatorFn<T> => (control) => {
  const { vin } = control.value;
  const { user } = control.value;
  return !vinRequiredByServiceSupplier() && !userIdRequiredByServiceSupplier() && !vin && !user
    ? { vinOrUser: true }
    : null;
};

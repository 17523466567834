<div class="search-wrapper wrapper wrapper--select">
  <input
    id="search"
    class="search-input"
    type="text"
    (focus)="handleFocus()"
    (keyup)="handleSearch($event)"
    [value]="inputValue"
    autocomplete="off"
  />
  <div class="dropdown" *ngIf="!!isDropdownOpen">
    <p
      class="no-results-message"
      *ngIf="
        displayedCustomerServices.length === 0 &&
        displayedCoreServices.length === 0 &&
        displayedFavouriteServices.length === 0 &&
        displayedCustomizedServices.length === 0
      "
      class="no-results"
    >
      {{ 'DIAGNOSIS.FORM.NO_RESULTS_FOUND' | translate }}
    </p>

    <p *ngIf="displayedFavouriteServices.length > 0" class="search-dropdown-category-title">
      Favourite Services
    </p>
    <ul class="search-dropdown-list" *ngIf="displayedFavouriteServices.length > 0">
      <li
        *ngFor="let favService of displayedFavouriteServices"
        id="{{ favService.modId }}"
        class="search-dropdown-list-item"
        (click)="handleSelect($event)"
      >
        {{ favService.name }}
      </li>
    </ul>
    <p *ngIf="displayedCustomerServices.length > 0" class="search-dropdown-category-title">
      {{ 'DIAGNOSIS.FORM.CUSTOMER_SERVICES' | translate }}
    </p>
    <ul class="search-dropdown-list">
      <li
        *ngFor="let customerService of displayedCustomerServices"
        id="{{ customerService.modId }}"
        class="search-dropdown-list-item"
        (click)="handleSelect($event)"
      >
        {{ customerService.name }}
      </li>
    </ul>
    <p *ngIf="displayedCoreServices.length > 0" class="search-dropdown-category-title">
      {{ 'DIAGNOSIS.FORM.CORE_SERVICES' | translate }}
    </p>
    <ul class="search-dropdown-list">
      <li
        *ngFor="let coreService of displayedCoreServices"
        id="{{ coreService.modId }}"
        class="search-dropdown-list-item"
        (click)="handleSelect($event)"
      >
        {{ coreService.name }}
      </li>
    </ul>
    <p *ngIf="displayedCustomizedServices.length > 0" class="search-dropdown-category-title">
      {{ 'DIAGNOSIS.FORM.CUSTOMIZED_SERVICES' | translate }}
    </p>
    <ul class="search-dropdown-list">
      <li
        *ngFor="let coreService of displayedCustomizedServices"
        id="{{ coreService.modId }}"
        class="search-dropdown-list-item"
        (click)="handleSelect($event)"
      >
        {{ coreService.name }}
      </li>
    </ul>
  </div>
</div>

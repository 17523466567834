export const environment = {
  production: false,
  e2edEnv: {
    issuer: 'https://idp-qa.cloud.vwgroup.com/auth/realms/kums-mfa',
    clientId: 'idp-605aaa0d-cbf3-4b80-aa17-cdd675b32797-E2ED-qa',
    regions: ['en', 'de'],
    dateTimeFormat: 'dd-MM-yyyy HH:mm',
    dateFormat: 'dd-MM-yyyy',
    features: {
      emailSearch: true
    },
    roles: {
      VWAG: {
        COMPETENCECENTER: ['VWAG_E2ED_PRE_COMPETENCECENTER', '0002039013', 'VWAG_E2ED_PRE_ADMIN'],
        CUSTOMERCARE: ['VWAG_E2ED_PRE_CUSTOMERCARE', '0002039014', 'VWAG_E2ED_PRE_ADMIN'],
        WORKSHOP: ['VWAG_E2ED_PRE_WORKSHOP', 'VWAG_E2ED_PRE_ADMIN'],
        CUSTOMER: ['VWAG_E2ED_PRE_CUSTOMER', 'VWAG_E2ED_PRE_ADMIN'],
        TESTER: ['VWAG_E2ED_PRE_TESTER', '0002039015', 'VWAG_E2ED_PRE_ADMIN']
      },
      SEAT: {
        COMPETENCECENTER: ['SEAT_E2ED_PRE_COMPETENCECENTER', '0002039004', 'SEAT_E2ED_PRE_ADMIN'],
        CUSTOMERCARE: ['SEAT_E2ED_PRE_CUSTOMERCARE', '0002039003', 'SEAT_E2ED_PRE_ADMIN'],
        WORKSHOP: ['SEAT_E2ED_PRE_WORKSHOP', 'SEAT_E2ED_PRE_ADMIN'],
        CUSTOMER: ['SEAT_E2ED_PRE_CUSTOMER', 'SEAT_E2ED_PRE_ADMIN'],
        TESTER: ['SEAT_E2ED_PRE_TESTER', '0002039005', 'SEAT_E2ED_PRE_ADMIN']
      },
      PRODUCTION: {
        COMPETENCECENTER: ['VWAG_E2ED_INTERN_INSPECTOR']
      },
      TEST: {
        COMPETENCECENTER: ['VWAG_E2ED_PRETEST_COMPETENCECENTER', 'VWAG_E2ED_PRETEST_ADMIN'],
        CUSTOMERCARE: ['VWAG_E2ED_PRETEST_CUSTOMERCARE', 'VWAG_E2ED_PRETEST_ADMIN'],
        WORKSHOP: ['VWAG_E2ED_PRETEST_WORKSHOP', 'VWAG_E2ED_PRETEST_ADMIN'],
        CUSTOMER: ['VWAG_E2ED_PRETEST_CUSTOMER', 'VWAG_E2ED_PRETEST_ADMIN']
      }
    }
  }
};

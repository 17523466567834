import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { env } from './environment';
import { UserService } from './shared/services/user-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'e2ed-diagnosis-tool';
  userLoggedIn = true;

  constructor(translate: TranslateService, userService: UserService) {
    translate.addLangs(env.regions);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(env.regions[0]);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(env.regions[0]);

    userService
      .getUser()
      .pipe(map((val) => !!val))
      .subscribe((userExists) => {
        this.userLoggedIn = userExists;
      });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/shared/interfaces/core-service.interface';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material/sidenav';
import { RolesService } from 'src/app/shared/services/roles-service.service';

@Component({
  selector: 'app-service-descriptions-list',
  templateUrl: './service-descriptions-list.component.html',
  styleUrls: ['./service-descriptions-list.component.scss']
})
export class ServiceDescriptionsListComponent implements OnInit {
  @Input() customerServicesToDisplay: CoreService[];
  @Input() servicesToDisplay: CoreService[];
  @Input() inputSideNav: MatSidenav;
  @Input() customizedServicesToDisplay: CoreService[];

  public openedCoreServices: Set<number>;
  public openedCustomerServices: Set<number>;
  public openedCustomizedServices: Set<number>;
  public isCustomizedServices: boolean = false;

  toggleServiceDescription(indexNumber: number, setToToggle: Set<number>) {
    setToToggle.has(indexNumber) ? setToToggle.delete(indexNumber) : setToToggle.add(indexNumber);
  }

  getTransaltedDescription(mapOfValues: object): string {
    const maptoCheck: Map<string, string> = new Map();
    for (const [key, value] of Object.entries(mapOfValues)) {
      maptoCheck.set(key, value);
    }
    if (
      maptoCheck.has(this.translateService.currentLang) &&
      maptoCheck.get(this.translateService.currentLang) !== ''
    ) {
      return maptoCheck.get(this.translateService.currentLang);
    } else if (maptoCheck.has('en') && maptoCheck.get('en') !== '') {
      return maptoCheck.get('en');
    } else {
      return '';
    }
  }

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.openedCoreServices = new Set();
    this.openedCustomerServices = new Set();
    this.openedCustomizedServices = new Set();

    this.isCustomizedServices = window.location.href.includes('customized-services-diagnosis');
  }
}

/** Time input should be between these two dates */
import { ValidatorFn } from 'ngx-typesafe-forms';

export function latestDate() {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
}

export function earliestDateWithTimeBuffer(): Date {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  date.setHours(date.getHours() + 1);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function dateTimeValidator(): ValidatorFn<Date> {
  return (control) => {
    const earliestDate = earliestDateWithTimeBuffer();
    const latestDateVar = latestDate();

    return control.value < earliestDate || control.value > latestDateVar
      ? { forbidden: { value: control.value } }
      : null;
  };
}

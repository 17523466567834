<button
  *ngIf="displayToggleButton"
  class="btn btn--tertiary flex--align-items-center mar-small"
  type="button"
  title="{{ (this.showMore ? 'DIAGNOSIS.TABLE.MINIMIZE_BUTTON_TOOLTIP_DESCRIPTION' : 'DIAGNOSIS.TABLE.SHOW_ALL_BUTTON_TOOLTIP_DESCRIPTION') | translate }}"
  (click)="toggleShowMore()"
>
  <ng-template [ngIf]="!this.showMore" [ngIfElse]="elseTemplate">
    {{ 'DIAGNOSIS.TABLE.SHOW_ALL_BUTTON' | translate }}
  </ng-template>

  <ng-template #elseTemplate>
    {{ 'DIAGNOSIS.TABLE.HIDE_ALL_BUTTON' | translate }}
  </ng-template>
</button>

<div class="row row--container mar-top-large">
  <div class="col sm-12">
    <div class="card">
      <div class="card__content">
        <div class="header">
          <button class="btn btn--primary" (click)="toggleIsOpen()" title="{{ (isChartOpen ? 'DIAGNOSIS.CHART.HIDE_STATISTICS_TOOLTIP_DESCRIPTION' : 'DIAGNOSIS.CHART.SHOW_STATISTICS_TOOLTIP_DESCRIPTION') | translate }}">
            {{
              'DIAGNOSIS.CHART.' + (isChartOpen ? 'HIDE_STATISTICS' : 'SHOW_STATISTICS') | translate
            }}
          </button>
          <ul class="results-info" *ngIf="!this.isChartOpen && numOfResults > 0">
            <li>{{ 'DIAGNOSIS.FOOTER.NUM_OF_RESULTS' | translate }} {{ numOfResults }}</li>
          </ul>
          <button *ngIf="this.isChartOpen" class="btn btn--primary" title="{{ 'DIAGNOSIS.CHART.RESET_ZOOM_TOOLTIP_DESCRIPTION' | translate }}" (click)="this.resetZoom()">
            {{ 'DIAGNOSIS.CHART.RESET_ZOOM' | translate }}
          </button>
        </div>
        <div class="num-of-hits-content" *ngIf="this.isChartOpen">
          <div *ngIf="this.chartBackendCalled" class="row row--container">
            <div class="col sm-12">
              <div>
                <div class="col sm-4 sm-offset-5">
                  <div class="preloader preloader--golf"></div>
                  <h6>{{ 'DIAGNOSIS.FORM.DIAGNOSIS_IN_PROGRESS' | translate }}</h6>
                </div>
              </div>
            </div>
          </div>
          <apx-chart
            #chart
            *ngIf="!this.chartBackendCalled"
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [yaxis]="chartOptions.yaxis"
            [xaxis]="chartOptions.xaxis"
            [fill]="chartOptions.fill"
            [title]="chartOptions.title"
          ></apx-chart>
          <ul class="results-info" *ngIf="this.isChartOpen">
            <li>{{ 'DIAGNOSIS.FOOTER.NUM_OF_RESULTS' | translate }} {{ numOfResults }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row select-service-wrapper">
  <link rel="stylesheet" href="/assets/dist/swagger.css" />
  <label>Select service:</label>
  <select
    class="service-api-select"
    (change)="updateSwagger($event.target.value)"
    [(ngModel)]="selectedService"
  >
    <option *ngFor="let key of specKeys()" [value]="key">{{ key }}</option>
  </select>
</div>
<div id="customer-api-documentation"></div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { Diagnosis } from '../models/diagnosis.model';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  sendFeedback(
    type: 'POSITIVE' | 'NEGATIVE',
    brand: string,
    diagnosis: Diagnosis,
    feedback: string,
    diagnosisRequestParams: object
  ) {
    return this.http.post(Constants.FEEDBACK_URL, {
      brand,
      modId: diagnosis.modId,
      type,
      description: diagnosis.failureDescription,
      solution: diagnosis.solution,
      feedback,
      diagnosisRequestParams
    });
  }
}
